.root {
  display: flex;
  height: calc(100vh - 55px);
  gap: 20px;

  .left {
    padding: 0 15px 15px 266px;
    border-right: 1px solid var(--text-color-1);
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1600px) {
      padding: 0 15px 15px 60px;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 300px;
      overflow-y: scroll;

      /*CUSTOM SCROLL BAR*/
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        margin-right: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #48515d;

        border-right: 6px rgba(0, 0, 0, 0) solid;
        background-clip: padding-box;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      @media only screen and (max-width: 1120px) {
        width: 200px;
      }

      .item {
        padding: 10px;
        border-radius: 12px;
        cursor: pointer;
        .itemSubtitle {
          text-align: left;
          font: normal normal 300 12px/25px Sofia Pro;
          letter-spacing: -0.12px;
          color: var(--text-color-1);
        }
        .itemTitle {
          text-align: left;
          font: normal normal bold 18px/18px Sofia Pro;
          letter-spacing: -0.27px;
          color: white;
        }
        &.active {
          background: #202934;
        }
        &:hover {
          background: #202934;
        }
      }
    }
  }
  .right {
    flex: 1;
    // background: rgba(255, 192, 203, 0.142);
    height: 100%;
    padding: 0 260px 0 15px;
    overflow-y: scroll;

    @media only screen and (max-width: 1360px) {
      padding: 0 60px 0 15px;
    }

    &::-webkit-scrollbar {
      /* Chrome, Safari and Opera */
      display: none;
    }

    .refItem {
      display: none;
      a {
        color: var(--accent-color-1) !important;
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
          color: var(--link) !important;
        }
      }
      img {
        width: 100%;
        object-fit: cover;
      }
      &.show {
        display: flex;
        flex-direction: column;
      }
    }

    // .refSubtitle {
    //   text-align: left;
    //   font-size: 1.6rem;
    //   letter-spacing: -0.43px;
    //   color: white;
    //   margin: 20px 0;
    //   line-height: 1.8rem;
    // }

    // img {
    //   width: 100%;
    //   margin-bottom: 40px;
    // }
    // p {
    //   text-align: left;
    //   font: normal normal medium 21px/32px Sofia Pro;
    //   letter-spacing: -0.32px;
    //   line-height: 1.4rem;
    //   margin: 20px 0;
    // }
  }
}

.title {
  color: white;
  text-align: left;
  font: normal normal bold 36px/43px Sofia Pro;
  letter-spacing: -0.54px;
  margin-bottom: 30px;
}
