.root {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  .option {
    display: block;
    padding: 0;
    margin: 0;
    &:hover {
      label {
        color: var(--link);
      }
    }
    label {
      padding: 5px 15px;
      margin-left: 15px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      color: var(--text-color-1);
      font-size: 0.95rem;
      cursor: pointer;
      transition: all 0.15s;
      word-break: break-all;
    }
    input {
      visibility: hidden;
      display: none;
      &:checked + label {
        color: var(--link);
      }
    }
  }
}
