.root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  flex: 1;
  .title {
    color: var(--text-color-1);
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 42px;
    margin-top: 50px;
    @media only screen and (max-height: 760px) {
      margin-top: 20px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media only screen and (max-height: 860px) {
      gap: 0;
    }
    .row {
      &.actions {
        margin-top: 30px;
        @media only screen and (max-height: 760px) {
          margin-top: 0;
        }
      }
    }
    .error {
      min-height: 20px;
      margin: 0;
      color: rgb(244, 123, 123);
    }
  }
}
