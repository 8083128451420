.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }

    .container {
      display: flex;
      flex-direction: column;
      // justify-content: flex-start;
      // flex-wrap: wrap;
      // position: relative;
      max-width: 1680px;
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      color: white;
      // HEADER
      .header {
        display: flex;
        // padding: 130px 24px 30px 24px;
        // margin-top: -100px;
        position: relative;
        gap: 35px;
        flex-wrap: wrap;
        margin-top: 30px;
        // @media only screen and (max-width: 1140px) {
        //   flex-direction: column;
        // }

        @media only screen and (max-width: 1540px) {
          .tabSwitch {
            display: none;
          }
        }

        @media only screen and (max-width: 1280px) {
          .tabSwitch {
            display: flex;
          }
        }
        // .course-overview-header-bg {
        //   height: 739px;
        //   overflow: hidden;
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   z-index: -1;
        //   img {
        //     width: 100%;
        //     height: 100%;
        //     object-fit: cover;
        //     object-position: center;
        //     filter: blur(50px);
        //     opacity: 0.4;
        //   }
        //   &:after {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;

        //     top: 0;
        //     left: 0;
        //     /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#191a23+0,191a23+100&0+0,1+85 */
        //     background: -moz-linear-gradient(
        //       top,
        //       rgba(25, 26, 35, 0) 0%,
        //       rgba(25, 26, 35, 1) 85%,
        //       rgba(25, 26, 35, 1) 100%
        //     ); /* FF3.6-15 */
        //     background: -webkit-linear-gradient(
        //       top,
        //       rgba(25, 26, 35, 0) 0%,
        //       rgba(25, 26, 35, 1) 85%,
        //       rgba(25, 26, 35, 1) 100%
        //     ); /* Chrome10-25,Safari5.1-6 */
        //     background: linear-gradient(
        //       to bottom,
        //       rgba(25, 26, 35, 0) 0%,
        //       rgba(25, 26, 35, 1) 85%,
        //       rgba(25, 26, 35, 1) 100%
        //     ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        //     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191a23', endColorstr='#191a23',GradientType=0 ); /* IE6-9 */
        //   }
        // }

        .videoPlayer {
          // display: flex;
          flex: 1;
          // height: 686px;
          // min-height: 686px;
          // overflow: hidden;
          padding: 12px;
          background: black;
          border-radius: 12px;
        }
      }
      // contents
      .body {
        display: flex;
        padding: 30px 0;
        gap: 35px;
        @media only screen and (max-width: 1280px) {
          flex-direction: column;
        }
        .left {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          .heading {
            display: flex;
            align-items: center;
            gap: 25px;
            .title {
              padding-left: 25px;
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              h5 {
                margin: 0;
                color: #fcfeff;
                font-weight: 300;
                font-size: 1.125rem;
                grid-area: subtitle;
                letter-spacing: -0.18px;
                line-height: 1.5625rem;
                word-break: break-all;
                text-overflow: ellipsis;
              }
              h1 {
                margin: 0;
                color: white;

                font-size: 3.0625rem;
                grid-area: title;
                font-weight: 400;
                letter-spacing: -0.73px;
                line-height: 3.6875rem;
                word-break: break-all;
                text-overflow: ellipsis;
              }
            }
            button {
              margin-left: auto;
            }
          }
          .content {
            display: flex;
            flex-direction: column;
            .tabs {
              display: flex;
              gap: 100px;
              width: 100%;
              margin-top: 51px;

              position: relative;
              &::after {
                content: "";
                width: 100%;
                bottom: 0;
                height: 1px;
                background: #303030;
                position: absolute;
                z-index: -1;
              }
              .tab {
                font-size: 1.2rem;
                font-weight: bold;
                padding-bottom: 14px;
                letter-spacing: -0.3px;
                background: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: none;
                outline: none;
                box-shadow: none;
                color: #fefefe;
                cursor: pointer;
                transition: color 0.2s;
                &.active {
                  color: var(--accent-color-1);
                  border-bottom: 2px solid var(--accent-color-1);
                }
                &:hover {
                  color: var(--accent-color-1);
                  // border-bottom: 2px solid var(--accent-color-1);
                }
              }
            }
            .tabContent {
              padding-left: 15px;
            }
          }
        }
        .right {
          width: 351px;
          max-width: 351px;
          min-width: 351px;
          min-height: 686px;
          flex: 1 1 auto;

          .tabSwitch {
            display: none;
          }
          @media only screen and (max-width: 1540px) {
            .tabSwitch {
              display: flex;
            }
          }

          @media only screen and (max-width: 1280px) {
            .tabSwitch {
              display: none;
            }
          }
          // @media only screen and (max-width: 1360px) {
          //   width: 300px;
          //   max-width: 300px;
          //   min-width: 300px;
          // }
          // @media only screen and (max-width: 1140px) {
          //   width: 100%;
          //   max-width: 100%;
          //   min-width: 100%;
          // }
        }
      }
    }
  }

  .tabSwitch {
    width: 351px;
    // min-height: 700px;
    background: black;
    border-radius: 12px;
    // position: relative;
    display: flex;
    flex-direction: column;
    z-index: 90;
    // @media only screen and (max-width: 1360px) {
    //   width: 300px;
    // }
    @media only screen and (max-width: 1280px) {
      width: 100%;
      height: 600px;
    }

    .top {
      padding: 0 30px;
      margin-bottom: 10px;
      margin-top: 15px;
      .tabs {
        display: flex;
        gap: 50px;
        .tab {
          font-size: 1.2rem;
          font-weight: bold;
          padding-bottom: 8px;
          cursor: pointer;
          color: var(--secondary);
          background: transparent;
          border: none;
          position: relative;
          transition: color 0.25s;
          &.active {
            color: var(--accent-color-1);
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 1px;
              background: var(--accent-color-1);
              bottom: 0;
              left: 0;
            }
          }

          &:hover {
            color: var(--accent-color-1);
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 1px;
              background: var(--accent-color-1);
              bottom: 0;
              left: 0;
            }
            span {
              color: white;
            }
          }

          span {
            position: absolute;
            background: var(--accent-color-1);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -6px;
            right: -12px;
            font-size: 0.8rem;
            padding: 5px;
            line-height: 0.4rem;
            color: white !important;
          }
        }

        .toggle {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 16px;
          margin-left: auto;

          input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--field-bg);
            -webkit-transition: 0.4s;
            transition: 0.4s;
            &.round {
              border-radius: 34px;
            }

            &.round:before {
              border-radius: 50%;
            }

            &.on {
              &:before {
                -webkit-transform: translateX(14px);
                -ms-transform: translateX(14px);
                transform: translateX(14px);
              }
            }
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 4px;
            bottom: -1px;
            background: var(--gradient-bg-1);
            -webkit-transition: all 0.4s;
            transition: all 0.4s;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
          .label {
            position: absolute;
            left: -4px;
            top: 23px;
            font-size: 0.75rem;
            font-weight: 300;
            color: var(--link);
            opacity: 0.5;
            &.isOn {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
