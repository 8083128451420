.player-wrapper {
  // z-index: 10;
  // width: 100%;
  // // height: 100%;
  // position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // background: black;
  // border-radius: 12px;

  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  // &:hover {
  //   .controls {
  //     visibility: visible;
  //     opacity: 1;
  //   }
  // }
  // .videoToggle {
  //   content: "";
  //   position: absolute;
  //   width: 90px;
  //   height: 90px;
  //   background: rgba(204, 51, 255, 0.7764705882);
  //   opacity: 0.7;
  //   border-radius: 50%;
  //   top: calc(50% - 45px);
  //   left: calc(50% - 45px);
  //   cursor: pointer;
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     width: 0px;
  //     height: 0px;
  //     border-style: solid;
  //     border-width: 0 20px 48px 20px;
  //     border-color: transparent transparent white transparent;
  //     transform: rotate(90deg);
  //     top: calc(50% - 24px);
  //     left: calc(50% - 15px);
  //   }
  // }
  // .controls {
  //   visibility: hidden;
  //   opacity: 0;
  //   display: flex;
  //   gap: 10px;
  //   position: absolute;
  //   bottom: 50px;
  //   left: 30px;
  //   width: calc(100% - 90px);
  //   content: "";
  //   background: #13171e61;
  //   border-radius: 12px;
  //   padding: 15px;
  //   justify-content: space-between;
  //   align-items: center;
  //   -webkit-transition: visibility 0s, opacity 0.2s linear;
  //   transition: visibility 0s, opacity 0.2s linear;
  //   .playToggle,
  //   .nextTrack,
  //   .toggleFullScreen {
  //     width: 25px;
  //     height: 25px;
  //     // background: rgba(255, 0, 0, 0.245);
  //     cursor: pointer;
  //     position: relative;
  //   }
  //   .playToggle {
  //     .play,
  //     .pause {
  //       content: "";
  //       position: absolute;
  //       width: 100%;
  //       height: 100%;
  //       top: 0;
  //       left: 0;
  //     }
  //     .play {
  //       &::before {
  //         content: "";
  //         position: absolute;
  //         width: 0px;
  //         height: 0px;
  //         border-style: solid;
  //         border-width: 0px 10px 20px 10px;
  //         border-color: transparent transparent #fefefe transparent;
  //         transform: rotate(90deg);
  //         top: calc(50% - 10px);
  //         left: calc(50% - 10px);
  //       }
  //     }

  //     .pause {
  //       &::before {
  //         content: "";
  //         position: absolute;
  //         width: 6px;
  //         height: calc(100% - 4px);
  //         background: #fefefe;

  //         top: 2px;
  //         left: 4px;
  //       }
  //       &::after {
  //         content: "";
  //         position: absolute;
  //         width: 6px;
  //         height: calc(100% - 4px);
  //         background: #fefefe;
  //         top: 2px;
  //         right: 4px;
  //       }
  //     }
  //   }

  //   .nextTrack {
  //     .next {
  //       content: "";
  //       position: absolute;
  //       width: 100%;
  //       height: 100%;
  //       top: 0;
  //       left: 0;
  //       &::before {
  //         content: "";
  //         position: absolute;
  //         width: 0px;
  //         height: 0px;
  //         border-style: solid;
  //         border-width: 10px 10px 14px 10px;
  //         border-color: transparent transparent #fefefe transparent;
  //         transform: rotate(90deg);
  //         top: calc(50% - 12px);
  //         left: calc(50% - 7px);
  //       }
  //       &::after {
  //         content: "";
  //         position: absolute;
  //         width: 4px;
  //         height: calc(100% - 4px);
  //         background: #fefefe;
  //         top: 2px;
  //         right: 3px;
  //       }
  //     }
  //   }

  //   .duration {
  //     font-size: 0.85rem;
  //     color: #fefefe;
  //   }

  //   .progress {
  //     cursor: pointer;
  //     display: flex;
  //     flex: 1;
  //     background: #4c505e;
  //     height: 6px;
  //     border-radius: 6px;
  //     // opacity: 0.8;
  //     position: relative;
  //     -webkit-transition: height 0.2s;
  //     transition: height 0.2s;
  //     &:hover {
  //       height: 10px;
  //     }
  //     .percentage {
  //       content: "";
  //       position: absolute;
  //       height: 100%;
  //       left: 0;
  //       top: 0;
  //       border-radius: 6px;
  //       background-color: var(--accent-color-1);
  //       width: 0%;
  //       // z-index: 3;
  //     }

  //     .thumbnail {
  //       width: 100px;
  //       height: 100px;
  //       position: absolute;
  //       bottom: 32px;
  //     }
  //   }
  //   .toggleFullScreen {
  //     &::before {
  //       content: "";
  //       position: absolute;
  //       width: calc(100% - 8px);
  //       height: calc(100% - 8px);
  //       top: 2px;
  //       left: 2px;
  //       border: 2px dashed #fefefe;
  //     }
  //   }
  // }
  .ambientBg {
    position: fixed;
    top: -110px;
    left: 0;
    width: 100vw;
    z-index: -10;
    height: 64vh;
    opacity: 0.2;

    // position: absolute;
    // bottom: -100px;
    // left: 50%;
    // width: 200px;
    // height: 200px;
    // z-index: 2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: "";
      -webkit-backdrop-filter: blur(30px);
      -o-backdrop-filter: blur(30px);
      -moz-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
    }
    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 1px;
      left: 0;
      content: "";

      background: -moz-linear-gradient(
        top,
        rgba(25, 26, 35, 0) 0%,
        rgba(25, 26, 35, 0.4) 50%,
        rgba(25, 26, 35, 1) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(25, 26, 35, 0) 0%,
        rgba(25, 26, 35, 0.4) 50%,
        rgba(25, 26, 35, 1) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(25, 26, 35, 0) 0%,
        rgba(25, 26, 35, 0.4) 50%,
        rgba(25, 26, 35, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191a23', endColorstr='#191a23',GradientType=0 ); /* IE6-9 */
    }
  }
  // video {
  //   width: 100%;
  //   height: auto;
  //   border-radius: 12px;
  // }
  // canvas {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  // }

  // iframe {
  //   width: 100%;
  //   // height: 100%;
  //   border-width: 0;
  //   border-radius: 12px;
  //   background: #000;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
