.progress-bar-2 {
  display: flex;
  align-items: center;
  gap: 15px;
  .label {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 0.7rem;
    font-weight: 400;
    gap: 5px;
    line-height: 1.5rem;
    letter-spacing: -0.12px;
    span {
      // font-size: 1rem;
      font-weight: 700;
    }
  }

  .progress-bar {
    background-color: #4c505e;
    // width: 75px;
    height: 5px;
    border-radius: 20px;
    .progress-value {
      // background-color: #2b8e87;
      width: 20px;
      height: 5px;
      border-radius: 20px;
    }
  }
}
