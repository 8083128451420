.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(27, 25, 25, 0.5);
  -webkit-backdrop-filter: blur(8px);
  -o-backdrop-filter: blur(8px);
  -moz-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  .wrapper {
    padding: 30px;
    min-height: 160px;
    min-width: 360px;
    background: var(--field-bg);
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    gap: 15px;
    &.error {
      .icon {
        background: var(--error-confirmation);
        font-size: 2.3rem;
        font-weight: 300;
        transform: rotate(45deg);
      }
      .message {
        color: var(--error-confirmation);
        font-size: 1.2rem;
        text-align: center;
      }
    }
    &.succes {
      .icon {
        background: var(--success-confirmation);
        font-size: 1.3rem;
      }
      .message {
        color: var(--success-confirmation);
        text-align: center;
      }
    }
    .icon {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      position: relative;

      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .message {
    }
    button {
      margin-top: auto;
      display: flex;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      min-height: 35px;

      border: none;
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
      gap: 5px;
      background: var(--gradient-bg-1);
      cursor: pointer;
      font-weight: 700;
      font-size: 1rem;
      letter-spacing: -0.18px;
      color: #fff;
      &:hover {
        background: var(--accent-color-1);
      }
    }
  }
}
