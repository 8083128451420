.course-overview-details {
  h1 {
    margin-bottom: 50px;
    font-size: 1.8125rem;
    font-weight: 400;
    letter-spacing: -0.43px;
    line-height: 2.25rem;

    color: var(--secondary);
  }
  h5 {
    margin-bottom: 50px;
    font-size: 1.8125rem;
    font-weight: 400;
    letter-spacing: -0.43px;
    line-height: 2.25rem;

    color: var(--secondary);
  }
  p {
    color: white;
    font-size: 1.3125rem;
    letter-spacing: -0.32px;
    line-height: 1.5625rem;
    margin-bottom: 24px;
  }
}
