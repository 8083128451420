.notifications-list {
  //   max-width: 382px;
  overflow-y: scroll;
  flex: 1;
  position: relative;
  padding: 33px 10px;

  /*CUSTOM SCROLL BAR*/
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 2px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 8px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #48515d;

    border-right: 6px rgba(0, 0, 0, 0) solid;
    background-clip: padding-box;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
