.activity {
  display: flex;
  align-items: stretch;
  height: 100%;
  .activity-wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    width: calc(100vw - 240px);
    transition: margin-left 0.15s;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
      width: calc(100vw - 50px);
    }

    .static-slide {
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: -100px;
      position: relative;
      height: 510px;

      h4 {
        padding: 200px 50px;
        color: white;
        text-align: center;
        font-size: 1.5rem;
        position: relative;
        z-index: 2;
      }
      /* SHADOW OVERLAY */
      &::after {
        content: "";

        position: absolute;
        width: 100%;
        height: 100%;
        bottom: -1px;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0d1118+0,0d1118+50,0d1118+100&0+0,0.4+50,1+100 */
        background: -moz-linear-gradient(
          top,
          rgba(13, 17, 24, 0) 0%,
          rgba(13, 17, 24, 0.4) 50%,
          rgba(13, 17, 24, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(13, 17, 24, 0) 0%,
          rgba(13, 17, 24, 0.4) 50%,
          rgba(13, 17, 24, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(13, 17, 24, 0) 0%,
          rgba(13, 17, 24, 0.4) 50%,
          rgba(13, 17, 24, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d1118', endColorstr='#0d1118',GradientType=0 ); /* IE6-9 */
      }
    }

    .activity-header {
      position: relative;
      min-height: 510px;
      // background: url("../../assets/images/activityImg.jpeg");
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;

      display: flex;
      /* SHADOW OVERLAY */
      &::after {
        content: "";

        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0d1118+0,0d1118+50,0d1118+100&0+0,0.4+50,1+100 */
        background: -moz-linear-gradient(
          top,
          rgba(13, 17, 24, 0) 0%,
          rgba(13, 17, 24, 0.4) 50%,
          rgba(13, 17, 24, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(13, 17, 24, 0) 0%,
          rgba(13, 17, 24, 0.4) 50%,
          rgba(13, 17, 24, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(13, 17, 24, 0) 0%,
          rgba(13, 17, 24, 0.4) 50%,
          rgba(13, 17, 24, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d1118', endColorstr='#0d1118',GradientType=0 ); /* IE6-9 */
      }

      .activity-header-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 24px;
        flex: 1;
        margin-top: auto;
        margin-bottom: 15px;
        z-index: 22;
        .subtitle {
          color: #fcfeff;
          font-size: 1.125rem;
          font-weight: 300;
          margin-bottom: 8px;
          margin-top: 35px;
        }
        .title {
          color: var(--secondary);
          font-size: 5rem;
          letter-spacing: -1.2px;
          line-height: 6rem;
          position: relative;
          margin-bottom: 31px;
          font-weight: 400;
          margin-top: 0;
          text-align: left;
          @media only screen and (max-width: 1100px) {
            font-size: 3rem;
            line-height: 4rem;
          }

          @media only screen and (max-width: 550px) {
            font-size: 2rem;
            line-height: 2rem;
          }
        }
      }
      .go-to-course {
        position: absolute;
        top: 65px;
      }
    }
    .course-timeline {
      // border: 0.2px solid grey;
      background: var(--secondary-bg);
      flex: 1;
      padding: 30px 12px;
    }
  }
}
