.root {
  display: flex;
  align-items: stretch;

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          // flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            line-height: 2.9375rem;
            color: var(--text-color-1);
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
      }
      .contents {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        min-height: 400px;
        padding: 10px;
        flex: 1;

        .videoPlayer {
          width: 100%;
          //   height: 100%;
          margin: 80px auto;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background: black;
          border-radius: 12px;

          .playbackAnimation {
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -40px;
            margin-top: -40px;
            width: 80px;
            height: 80px;
            border-radius: 80px;
            background-color: #cc33ff72;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;

            svg {
              width: 40px;
              height: 40px;
              fill: #fff;
              stroke: #fff;
              cursor: pointer;
            }
          }
          video {
            width: 100%;
            height: auto;
            border-radius: 20px;
          }
        }
      }
    }
  }
}
