.course-overview-playlist {
  padding-bottom: 12px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  .drop-menu-wrapper {
    padding: 0 30px;
    margin-top: 15px;
  }
  .playlist-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    // height: 578px;
    overflow-y: scroll;
    padding: 0 12px;

    /*CUSTOM SCROLL BAR*/
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      margin-right: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      border-radius: 8px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #48515d;

      border-right: 6px rgb(0, 0, 0) solid;
      background-clip: padding-box;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
