.root {
  display: flex;
  background: var(--field-bg);
  border-radius: 12px;
  margin: 15px 0;
  cursor: pointer;
  .wrapper {
    display: flex;
    // flex-wrap: wrap;

    flex: 1;
    flex-direction: column;
    .header {
      display: flex;
      flex-direction: row;
      margin: 15px 20px;
      align-items: center;
      gap: 20px;
      position: relative;
      .title {
        font-size: 1.3rem;
        letter-spacing: -0.24px;
        color: white;
        font-weight: 700;
        line-height: 1.8rem;
      }

      .icon {
        transform: rotate(-90deg);
        width: 22px;
        height: 22px;
        opacity: 0.3;
        transition: transform 0.15s;
        position: absolute;
        top: 2px;
        right: 5px;
      }

      &.expanded {
        .icon {
          transform: rotate(90deg);
          //   top: -4px;
        }
      }
    }

    .body {
      margin: 20px 0;
      font-size: 1.2rem;
      letter-spacing: -0.24px;
      color: rgb(241, 241, 241);
      font-weight: 300;
      padding: 0 20px;
    }
  }
}
