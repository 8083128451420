.chat {
  position: relative;
  flex-direction: column;
  gap: 15px;
  display: flex;
  flex: 1;
  padding: 20px 0;
  .wrapper {
    overflow-y: scroll;
    flex: 1;
    position: relative;

    /*CUSTOM SCROLL BAR*/
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      margin-right: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      border-radius: 8px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #48515d;

      border-right: 6px rgb(0, 0, 0) solid;
      background-clip: padding-box;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
