.progress-bar-1 {
  display: flex;
  flex-direction: column;

  .label {
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    gap: 5px;
    line-height: 1.5rem;
    letter-spacing: -0.12px;
    span {
      font-size: 1.25rem;
    }
  }

  .progress-bar {
    background-color: #4c505e;
    // width: 75px;
    height: 5px;
    border-radius: 20px;
    .progress-value {
      background-color: var(--accent-color-1);
      width: 20px;
      height: 5px;
      border-radius: 20px;
    }
  }
}
