.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(27, 25, 25, 0.5);
    -webkit-backdrop-filter: blur(8px);
    -o-backdrop-filter: blur(8px);
    -moz-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: -1;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    min-width: 400px;

    background: var(--secondary-bg);

    border-radius: 12px;
    color: white;
    &.fullScreen {
      width: 100vw;
      height: 100vh;
    }
  }
}
