.sidebar-menu {
  background-color: var(--secondary-bg);
  //   position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 99;
  width: 220px;
  height: calc(100vh - 63px);
  padding: 30px 20px 33px 0;
  transition: width 0.15s;
  position: fixed;
  height: calc(100% - 63px);
  &.show-notifications {
    &::before {
      content: "";
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 240px;
      top: 0;
      background-color: rgba(27, 25, 25, 0.5);
      -webkit-backdrop-filter: blur(8px);
      -o-backdrop-filter: blur(8px);
      -moz-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
    }
  }

  &.collapsed {
    display: flex;
    width: 50px;
    padding-right: 0;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: 780px) {
    display: flex;
    width: 50px;
    padding-right: 0;
    flex-direction: column;
    align-items: center;
  }
  // sidebar logo

  .sidebar-logo {
    margin: 0 0 23px 28px;
    display: block;
    width: 130px;
    height: 46px;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
    }
    &.collapsed {
      margin-left: 0;
      width: 26px;
      overflow: hidden;

      img {
        width: 100px;
        position: absolute;
      }
    }

    @media only screen and (max-width: 780px) {
      margin-left: 0;
      width: 26px;
      overflow: hidden;

      img {
        width: 100px;
        position: absolute;
        object-fit: cover;
        object-position: left;
      }
    }
  }

  //sidebar expander

  .sidebar-expander {
    margin-bottom: 55px;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 780px) {
      display: none;
    }

    span {
      cursor: pointer;
      display: flex;
      padding-left: 12px;
      justify-content: center;
      align-items: center;
    }
  }

  // notifications

  .sidebar-notifications {
    margin-bottom: 18px;
    display: flex;
    gap: 12px;
    padding-left: 28px;
    cursor: pointer;
    &.collapsed {
      padding-left: 0;
      .bell {
        position: relative;
        padding-left: 10px;
      }
    }

    @media only screen and (max-width: 780px) {
      padding-left: 0;

      .label {
        display: none;
      }
    }

    .bell {
      position: relative;
    }
    .count {
      position: absolute;
      right: 5px;
      top: -7px;
      background-color: var(--accent-color-1);
      border-radius: 50%;
      width: 14px;
      height: 14px;
      font-size: 0.5rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #f2ffff;
    }

    .label {
      font-size: 0.8125rem;
      line-height: 1.1875rem;
      letter-spacing: -0.13px;
      color: #9d9ca0;
    }
  }

  // class select

  .sidebar-class-selection {
    padding: 0 20px 43px;

    @media only screen and (max-width: 780px) {
      display: none;
    }
  }

  // menu list
  // .menu-list {
  //   display: flex;
  //   flex-direction: column;
  //   flex: 1;

  //   .sidebar-menu-item {
  //     font-weight: bold;
  //     font-size: 1rem;
  //     margin: 20px 0;
  //     height: 30px;
  //     padding-left: 24px;
  //     display: flex;
  //     gap: 10px;
  //     align-items: center;
  //     border-left: 3px solid var(--secondary-bg);
  //     transition: 0.3s ease border;
  //     cursor: pointer;
  //     color: white;
  //     transition: all 0.25s;

  //     &.last {
  //       margin-top: auto;
  //     }

  //     &.active,
  //     &:hover {
  //       svg path {
  //         fill: var(--accent-color-1) !important;
  //       }

  //       color: var(--accent-color-1) !important;
  //       border-left-color: var(--accent-color-1);
  //     }
  //     &.collapsed {
  //       padding-left: 0;
  //       border-left-width: 0;
  //       transition: none;
  //       span {
  //         display: none;
  //       }

  //       &.active,
  //       &:hover {
  //         border-left-width: 0;
  //       }
  //     }

  //     @media only screen and (max-width: 780px) {
  //       padding-left: 0;
  //       border-left-width: 0;
  //       transition: none;

  //       &.active,
  //       &:hover {
  //         border-left-width: 0;
  //       }
  //     }
  //     span {
  //       margin-top: 2px;
  //       @media only screen and (max-width: 780px) {
  //         display: none;
  //       }
  //     }
  //   }
  // }

  //notifications list

  .notification-container {
    display: flex;
    flex-direction: column;
    width: 382px;
    height: calc(100% - 66px);
    padding: 33px 0;
    background-color: var(--third-bg);
    box-shadow: 0 3px 6px #00000029;
    position: absolute;
    top: 0;
    left: 240px;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    z-index: 999;
    transition: left 0.15s;
    position: absolute;

    &.collapsed {
      left: 50px;
    }

    &::-webkit-scrollbar {
      /* Chrome, Safari and Opera */
      display: none;
    }
    .title {
      font-size: 1rem;
      letter-spacing: -0.16px;
      margin-bottom: 10px;
      color: var(--primary);
      padding: 0 10px;
    }

    .markAllAsRead {
      cursor: pointer;
      font-size: 0.75rem;
      letter-spacing: -0.11px;
      color: var(--accent-color-1);
      padding: 0 10px;
    }

    @media only screen and (max-width: 780px) {
      left: 50px;
    }
  }
}
