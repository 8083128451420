.root {
  margin: 10px;
  display: flex;
  flex-direction: column;
  background: var(--secondary-bg);
  align-items: stretch;
  border-radius: 12px;
  box-shadow: 0 11px 14px #0000001a;

  .wrapper {
    display: flex;
    flex: 1;
    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }
    .avatar {
      width: 351px;
      height: 194px;
      overflow: hidden;
      cursor: pointer;
      @media only screen and (max-width: 1600px) {
        height: 220px;
      }
      @media only screen and (max-width: 1400px) {
        height: 240px;
      }
      @media only screen and (max-width: 1200px) {
        height: 194px;
        width: 100%;
        margin-bottom: 15px;
      }
      img {
        border-radius: 12px 0 0 12px;
        // width: 100%;
        // height: 100%;
        // object-fit: cover;
        @media only screen and (max-width: 1200px) {
          border-radius: 0;
        }
      }
    }
    .details {
      padding: 0 25px;
      flex: 1;
      position: relative;
      cursor: pointer;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        margin: 0;
        color: white;
        font-weight: bold;
        font-size: 1.625rem;
      }
      h4 {
        margin: 0;
        color: var(--text-color-1);
        font-size: 0.875rem;
        font-weight: 300;
      }
      .description {
        font-size: 1rem;
        font-weight: 300;
        color: var(--text-color-1);
        padding: 10px 0;
        letter-spacing: -0.32px;
        line-height: 1.3rem;
        min-height: 50px;
      }

      .footer {
        display: flex;
        margin-top: 15px;
        gap: 15px;
        justify-content: flex-end;
        align-items: center;
        @media only screen and (max-width: 1200px) {
          margin-bottom: 15px;
        }
        .instructor {
          display: flex;
          align-items: center;
          gap: 10px;

          font-size: 0.875rem;
          font-weight: 300;
          flex: 1;
          img {
            border-radius: 50%;
            width: 24px;
            height: 24px;
          }
          span {
            color: var(--text-color-1);
          }
        }
        .terms {
          font-size: 0.875rem;
          font-weight: 300;
          // width: 70px;
          color: var(--text-color-1);
          span {
            font-weight: 800;
          }
        }
        .weeks {
          font-size: 0.875rem;
          font-weight: 300;
          // width: 70px;
          color: var(--text-color-1);
          span {
            font-weight: 800;
          }
        }
      }

      .arrow {
        height: 24px;
        transform: rotate(-90deg);
        width: 14px;
        // opacity: 0.3;
        position: absolute;
        top: 25px;
        right: 35px;
        transition: transform 0.15s;
        @media only screen and (max-width: 1200px) {
          top: 0;
          right: 25px;
        }
      }

      &.expanded {
        .arrow {
          height: 24px;
          transform: rotate(90deg);
          width: 14px;
          position: absolute;
          top: 25px;
          right: 35px;

          @media only screen and (max-width: 1200px) {
            top: 0px;
            right: 25px;
          }
        }
      }
    }
  }
  .termList {
    display: none;
    padding: 20px;
    min-height: 200px;
    margin-top: 20px;
    &.expanded {
      display: flex;
      flex-direction: column;
    }
    h2 {
      margin: 0 10px;
      color: white;
      font-weight: bold;
      font-size: 1.4rem;
      color: var(--text-color-1);
      padding-bottom: 15px;
    }
    h5 {
      margin: 0 10px;
      color: var(--text-color-1);
      font-size: 0.875rem;
      font-weight: 300;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      width: 100%;
    }
  }
}
