.root {
  position: relative;
  width: auto;
  margin: 0;
  padding: 0;
  .dots {
    cursor: pointer;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 0;
    text-align: center;
    color: var(--primary);
  }
  .modList {
    position: absolute;
    top: 20px;
    left: -50%;
    min-width: 100px;
    // min-height: 30px;

    border-radius: 6px;
    padding: 20px 12px;
    flex-direction: column;
    gap: 10px;
    display: flex;
    z-index: 10;

    background: var(--secondary-bg);

    // &.comment {
    //   background: var(--field-bg);
    // }

    @media only screen and (max-width: 1140px) {
      left: -110%;
      color: var(--primary);
      display: flex;
    }

    span {
      color: var(--primary);
      cursor: pointer;
      font-size: 0.9rem;
    }
  }
}
