.helpTab {
  // .details {
  // display: flex;

  padding: 65px 0;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #13151a;
    border: 1px solid #303030;
    border-radius: 12px;
    gap: 30px;
    @media only screen and (max-width: 980px) {
      flex-direction: column;
    }
    .left {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .title {
        font-size: 1.5rem;
        font-weight: 400;
        // margin-bottom: 29px;
        line-height: 1.6rem;
        color: white;
      }
      .subtitle {
        font-size: 1.3rem;
        letter-spacing: -0.24px;
        color: var(--text-color-1);
        // margin-bottom: 10px;
      }
    }
    .right {
      display: flex;

      button {
        width: 120px;
        @media only screen and (max-width: 780px) {
          width: 200px;
        }
      }
    }
  }

  // }
  .list {
    margin: 0 10px;
    display: grid;
    gap: 2rem;

    @media (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1500px) {
      grid-template-columns: repeat(4, 1fr);
    }
    .categoryItem {
      background-color: var(--secondary-bg);
      color: var(--text-color-1);
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.1rem;
      letter-spacing: -0.24px;
      min-height: 4rem;
      cursor: pointer;
      -webkit-transition: all 0.15s;
      -o-transition: all 0.15s;
      transition: all 0.15s;
      &:hover {
        color: white;
      }
    }
  }
}
