.root {
  display: block;
  position: relative;

  img {
    object-fit: cover;
    border-radius: 3px;
    &.rounded {
      border-radius: 50%;
    }
  }
}
