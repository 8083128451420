.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  @media only screen and (max-height: 650px) {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      margin-right: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      border-radius: 8px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #48515d;

      border-right: 6px var(--secondary-bg) solid;
      background-clip: padding-box;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .item {
    font-weight: bold;
    font-size: 1rem;
    margin: 20px 0;
    height: 30px;
    padding-left: 24px;
    display: flex;
    gap: 10px;
    align-items: center;
    border-left: 3px solid var(--secondary-bg);
    transition: 0.3s ease border;
    cursor: pointer;
    color: white;
    transition: all 0.25s;

    .discord {
      width: 100px;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }
    @media only screen and (max-height: 768px) {
      margin: 10px 0;
    }

    // &:last-child {
    //   margin-top: auto;
    // }

    &.active,
    &:hover {
      svg path {
        fill: var(--accent-color-1) !important;
      }

      color: var(--accent-color-1) !important;
      border-left-color: var(--accent-color-1);
    }

    @media only screen and (max-width: 780px) {
      padding-left: 0;
      border-left-width: 0;
      transition: none;

      .discord {
        width: 24px;
        height: 24px;
        object-fit: cover;
        object-position: 0;
      }

      &.active,
      &:hover {
        border-left-width: 0;
      }
    }
    span {
      margin-top: 2px;
      @media only screen and (max-width: 780px) {
        display: none;
      }
    }
  }

  .lastItem {
    margin-top: auto;
    display: flex;
    flex-direction: column;
  }
  .terms {
    font-weight: 400;
    font-size: 0.85rem;
    margin: 4px 0;
    // height: 30px;
    padding-left: 24px;

    // display: flex;
    // gap: 10px;
    align-items: center;
    border-left: 3px solid var(--secondary-bg);
    transition: 0.3s ease border;
    cursor: pointer;
    color: rgb(118, 118, 118) !important;
    transition: all 0.25s;
    &:hover {
      color: rgb(213, 213, 213) !important;
    }
    @media only screen and (max-width: 780px) {
      display: none;
    }
  }
  &.collapsed {
    .item {
      padding-left: 0;
      border-left-width: 0;
      transition: none;
      .discord {
        width: 24px;
        height: 24px;
        object-fit: cover;
        object-position: 0;
      }
      span {
        display: none;
      }

      &.active,
      &:hover {
        border-left-width: 0;
      }
    }
    .terms {
      display: none;
    }
  }
}
