.playlist-item {
  display: flex;
  padding: 10px 18px;
  border-radius: 12px;
  cursor: pointer;
  gap: 13px;
  &:hover {
    background: var(--secondary-bg);
  }
  &.active {
    background: var(--secondary-bg);
  }
  cursor: pointer;
  .left {
    display: flex;
    flex-direction: column;
    .avatar {
      height: 68px;
      width: 68px;
      min-width: 68px;
      background-position: 50% !important;
      background-size: cover !important;
      border-radius: 12px;
      border: 1px solid rgba(57, 57, 57, 0.26);
      display: flex;
      margin-bottom: 4px;
      // img {
      //   height: 68px;
      //   width: 68px;
      //   border-radius: 12px;
      // }
    }
  }

  .details {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    .subtitle {
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 1rem;
      color: var(--primary);
    }
    .title {
      color: var(--secondary);
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: bold;
      word-break: break-all;
    }
    .duration {
      color: var(--secondary);
      font-weight: 300;
      font-size: 0.625rem;
      line-height: 1.2rem;
      margin-top: auto;
    }
  }
}
