.notification-item {
  border-bottom: 1px solid rgba(117, 129, 147, 0.05);
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 15px 5px;

  &.from-sys-admin {
    background: #9c47871f;
  }

  .avatar {
    position: relative;
    width: 38px;
    height: 38px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .status {
      position: absolute;
      top: -5px;
      right: -5px;
      // background-color: var(--color);
      border: 1px solid var(--main-bg);
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }
  }

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    // gap: 10px;
    .contents {
      font-size: 0.75rem;
      font-weight: 300;
      letter-spacing: -0.24px;
      color: #f2ffff;
      line-height: 0.9375rem;
      font-weight: bold;

      &.read {
        color: #758193;
      }
      .description {
        margin-top: 6px;
        font-weight: 300;
        display: none;
        p {
          margin: 2px 0;
          cursor: pointer;
          color: var(--accent-color-1);
          font-weight: normal;
        }
        &.show {
          display: block;
        }
      }
    }
    // .more {
    //   text-transform: uppercase;
    //   font-size: 0.5rem;
    //   font-weight: bold;
    //   text-decoration: underline;
    //   cursor: pointer;
    //   color: #758193;
    //   &:hover {
    //     text-decoration: none;
    //   }
    // }

    .time {
      font-size: 0.625rem;
      font-weight: 300;
      color: var(--primary);
      // margin-top: 10px;
    }
  }
}
