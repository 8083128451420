.ticketTab {
  padding: 65px 0;
  .header {
    display: flex;

    align-items: center;
  }

  .tickets {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    margin-top: 60px;
    margin-bottom: 100px;
    .filterBar {
      display: flex;
      flex-direction: row;
      margin: 5px 15px;
      align-items: center;
      gap: 20px;
      @media only screen and (max-width: 1220px) {
        flex-direction: column;
        margin-right: 50px;
        gap: 0;
        .headRow {
          display: flex;
          flex: 1;
          width: 100%;
          justify-content: space-between;
          &:not(&:first-child) {
            display: none;
          }
        }
        span {
          width: auto;
          &.icon {
            position: absolute;
            top: 12px;
            right: 15px;
          }
        }
      }
      .headRow {
        display: flex;
        align-items: center;
        gap: 24px;
        &:first-child {
          flex: 1;
        }
      }
      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--text-color-1);

        font-size: 0.77rem;
        font-weight: 400;
        letter-spacing: -0.28px;

        letter-spacing: -0.28px;
        &.caseId {
          width: 40px;
        }
        &.user {
          display: flex;
          flex-direction: row;
          gap: 15px;
          width: 220px;
        }
        &.subject {
          flex: 1 1;
          display: flex;
        }
        &.icon {
          margin-left: auto;
          cursor: pointer;
          width: 14px;
          display: flex;
        }

        &.status {
          width: 85px;
        }
        &.priority {
          width: 80px;
        }
        &.department {
          width: 90px;
        }
        &.date {
          width: 80px;
        }
      }
    }
    // .head {
    //   display: flex;
    //   padding: 15px;
    //   span {
    //     color: var(--text-color-1);
    //     font-weight: 300;
    //     font-size: 1rem;
    //     letter-spacing: -0.16px;
    //     min-width: 150px;
    //     &.subject {
    //       min-width: 350px;
    //       flex: 1;
    //     }
    //   }
    // }

    // .item {
    //   background: var(--field-bg);
    //   margin: 20px 0;
    //   padding: 15px;
    //   border-radius: 12px;
    //   display: flex;
    //   align-items: center;
    //   span {
    //     color: white;
    //     font-weight: 300;
    //     font-size: 1rem;
    //     letter-spacing: -0.16px;
    //     min-width: 150px;
    //     &.subject {
    //       min-width: 350px;
    //       flex: 1;
    //     }
    //   }
    // }
  }
}
