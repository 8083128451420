.root {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;

  &.passwordType {
    .input {
      padding-right: 60px;
    }
  }

  input {
    background-color: var(--field-bg);
    color: var(--link);
    border: 2px px solid var(--field-bg);
    border-radius: 12px;
    font-size: 1.125rem;
    padding: 20px 15px 5px 15px;
    line-height: 2.5rem;
    outline: none;
    display: flex;
    flex: 1 1;
    z-index: 1;
    @media only screen and (max-height: 680px) {
      padding: 10px 15px 5px 15px;
    }
    @media only screen and (max-height: 620px) {
      padding: 7px 15px 2px 15px;
    }

    &:disabled {
      color: var(--text-color-1);
    }

    &:focus {
      background-color: var(--main-bg);
      box-shadow: inset 0 0 2px var(--text-color-1);
    }
  }

  .label {
    cursor: text;
    position: absolute;
    left: 15px;
    top: 20%;
    background: transparent;
    color: var(--text-color-1);
    font-size: 0.75rem;
    transition: 0.3s;
    z-index: 2;
    @media only screen and (max-height: 680px) {
      top: 10%;
    }
  }

  .showHidePassword {
    position: absolute;
    top: 24px;
    right: 10px;
    z-index: 2;
    color: var(--text-color-1);
    font-size: 0.9rem;
    cursor: pointer;
    padding: 2px;
  }

  .error {
    padding: 0;
    margin: 0;
    height: 12px;
    padding: 2px 0;
    bottom: -14px;
    color: var(--error-color);
    font-weight: 400;
    font-size: 0.775rem;
    text-transform: capitalize;
  }
}
