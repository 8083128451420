// .item {
//   background: var(--field-bg);
//   padding: 15px;
//   margin: 15px 0;
//   border-radius: 12px;

//   .summary {
//     display: flex;
//     span {
//       color: white;
//       font-weight: 300;
//       font-size: 1rem;
//       letter-spacing: -0.16px;
//       width: 150px;
//       &.download {
//         cursor: pointer;
//         width: 20px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         border: 1px solid var(--text-color-1);
//         color: var(--text-color-1);
//       }
//       &.arrow {
//         margin-left: auto;
//         width: 12px;
//         cursor: pointer;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         svg {
//           width: 100%;
//           transform: rotate(-90deg);
//           opacity: 0.6;
//           transition: all 0.25s;
//           &.expanded {
//             transform: rotate(90deg);
//           }
//         }
//       }
//     }
//   }
//   .details {
//     margin-top: 20px;
//     .row1 {
//       padding: 15px 0;
//       border-bottom: 1px solid #7a879824;
//       display: flex;
//       span {
//         color: white;
//         font-weight: 300;
//         font-size: 1rem;
//         letter-spacing: -0.16px;
//         width: 150px;
//       }
//     }
//     .row2 {
//       padding: 15px 0;
//       display: flex;
//       span {
//         color: white;
//         font-weight: 300;
//         font-size: 1rem;
//         letter-spacing: -0.16px;
//         width: 150px;
//       }
//     }
//   }
// }

span {
  // color: white;
  // font-weight: 300;
  // font-size: 1rem;
  // letter-spacing: -0.16px;
  // width: 150px;
  &.download {
    cursor: pointer;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--text-color-1);
    color: var(--text-color-1);
  }
  &.arrow {
    // margin-left: auto;
    width: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      transform: rotate(-90deg);
      opacity: 0.6;
      transition: all 0.25s;
      &.expanded {
        transform: rotate(90deg);
      }
    }
  }
}
