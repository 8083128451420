.message-submitter {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  form {
    display: flex;
    gap: 10px;
    .emojiShow {
      padding-top: 2px;
      font-size: 1.2rem;
      padding-top: 2px;
      cursor: pointer;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        opacity: 1;
      }
    }
    input {
      display: flex;
      flex: 1;
    }
  }

  .error {
    color: var(--error-color);
    margin: 5px 0 0 40px;
    font-size: 12px;
  }

  .EmojiPickerReact {
    position: absolute !important;
    padding: 5px;
    z-index: 46;
    // width: 100% !important;
    bottom: 44px !important;
    --epr-emoji-size: 22px;
    --epr-header-padding: 5px;
    --epr-search-input-height: 30px;
    --epr-category-navigation-button-size: 20px;
    --epr-category-label-height: 28px;
    .epr-body {
      &::-webkit-scrollbar {
        width: 8px;
        margin-right: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #48515d;

        border-right: 3px #222 solid;
        background-clip: padding-box;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .show-emoji {
    padding-bottom: 15px;
  }
}
