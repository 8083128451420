// .barImage {
//   border-radius: 12px;
// }

.bar {
  cursor: pointer;
  background: #27122a;
  overflow: hidden;
}
.bar-container {
  padding: 10px;
}

.course-title {
  color: #fcfeff;
  font-size: 20px;
  letter-spacing: -0.14px;
  font-weight: 600;
  line-height: 26px;
  height: 30px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.course-structure {
  color: #a3a3a3;
  font-size: 14px;
  letter-spacing: -0.14px;
  font-weight: 300;
  line-height: 26px;
  height: 20px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.course-date {
  color: #f3f3f3;
  font-size: 14px;
  letter-spacing: -0.14px;
  font-weight: 300;
  line-height: 26px;
  height: 20px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
