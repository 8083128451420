.invoices {
  .details {
    display: flex;
    flex-direction: column;
    padding: 65px 0;
    .header {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 1.5rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        line-height: 1.0625rem;
        color: var(--text-color-1);
      }
    }
    .row {
      display: flex;
      gap: 15px;
      padding: 10px 0;
      input {
        flex: 1;
        background-color: var(--field-bg);
        color: var(--link);
        border: 2px px solid var(--field-bg);
        border-radius: 12px;
        font-size: 1.125rem;
        padding: 15px;
        outline: none;
        min-width: 320px;
        color: white;
        &:focus {
          background-color: var(--secondary-bg);
          box-shadow: inset 0 0 2px var(--text-color-1);
        }

        &::placeholder {
          color: var(--text-color-1);
        }
        &.address {
          flex: 2;
        }
      }
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    .titleList {
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 29px;
      line-height: 1.0625rem;
      color: var(--text-color-1);
    }
    .contents {
      .columns {
        display: flex;
        padding: 15px;
        span {
          color: var(--text-color-1);
          font-weight: 300;
          font-size: 1rem;
          letter-spacing: -0.16px;
          width: 150px;
        }
      }
    }
  }
  table {
    border-collapse: collapse;
    // border-radius: 10px;
    th,
    td {
      padding: 15px 10px;
    }
    th {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.0625rem;
      color: var(--text-color-1);
      text-align: left;
    }

    tbody {
      tr {
        background-color: var(--field-bg);
        border-bottom: 10px solid var(--main-bg);

        // &:hover {
        //   background-color: var(--secondary-bg);
        // }
        td:first-child {
          border-radius: 20px 0 0 20px;
        }
        td:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }
  }
}
