.week {
  margin: 12px;
  cursor: pointer;
  .title {
    font-weight: 700;
    color: white;
    height: 22px;
    letter-spacing: -0.32px;
    line-height: 1.3rem;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  opacity: 0.5;
  transition: opacity 0.25s;
  &:hover {
    opacity: 1;
  }
}
