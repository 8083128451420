.loader {
  width: 100%;
  height: 100%;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: loading-animation 1s ease-in-out infinite;
    animation: loading-animation 1s ease-in-out infinite;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.course:before {
    background: -moz-linear-gradient(
      -45deg,
      rgba(13, 17, 24, 1) 0%,
      rgba(13, 17, 24, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(13, 17, 24, 0.58) 70%,
      rgba(13, 17, 24, 1) 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      right bottom,
      color-stop(0%, rgba(13, 17, 24, 1)),
      color-stop(30%, rgba(13, 17, 24, 0.58)),
      color-stop(50%, rgba(43, 43, 43, 0.3)),
      color-stop(70%, rgba(13, 17, 24, 0.58)),
      color-stop(100%, rgba(13, 17, 24, 1))
    );
    background: -webkit-linear-gradient(
      -45deg,
      rgba(13, 17, 24, 1) 0%,
      rgba(13, 17, 24, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(13, 17, 24, 0.58) 70%,
      rgba(13, 17, 24, 1) 100%
    );
    background: -o-linear-gradient(
      -45deg,
      rgba(13, 17, 24, 1) 0%,
      rgba(13, 17, 24, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(13, 17, 24, 0.58) 70%,
      rgba(13, 17, 24, 1) 100%
    );
    background: -ms-linear-gradient(
      -45deg,
      rgba(13, 17, 24, 1) 0%,
      rgba(13, 17, 24, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(13, 17, 24, 0.58) 70%,
      rgba(13, 17, 24, 1) 100%
    );
    background: linear-gradient(
      135deg,
      rgba(13, 17, 24, 1) 0%,
      rgba(13, 17, 24, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(13, 17, 24, 0.58) 70%,
      rgba(13, 17, 24, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d1118', endColorstr='#0d1118',GradientType=1 );
  }
  &.term:before {
    background: -moz-linear-gradient(
      -45deg,
      rgba(25, 26, 35, 1) 0%,
      rgba(25, 26, 35, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(25, 26, 35, 0.58) 70%,
      rgba(25, 26, 35, 1) 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      right bottom,
      color-stop(0%, rgba(25, 26, 35, 1)),
      color-stop(30%, rgba(25, 26, 35, 0.58)),
      color-stop(50%, rgba(43, 43, 43, 0.3)),
      color-stop(70%, rgba(25, 26, 35, 0.58)),
      color-stop(100%, rgba(25, 26, 35, 1))
    );
    background: -webkit-linear-gradient(
      -45deg,
      rgba(25, 26, 35, 1) 0%,
      rgba(25, 26, 35, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(25, 26, 35, 0.58) 70%,
      rgba(25, 26, 35, 1) 100%
    );
    background: -o-linear-gradient(
      -45deg,
      rgba(25, 26, 35, 1) 0%,
      rgba(25, 26, 35, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(25, 26, 35, 0.58) 70%,
      rgba(25, 26, 35, 1) 100%
    );
    background: -ms-linear-gradient(
      -45deg,
      rgba(25, 26, 35, 1) 0%,
      rgba(25, 26, 35, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(25, 26, 35, 0.58) 70%,
      rgba(25, 26, 35, 1) 100%
    );
    background: linear-gradient(
      135deg,
      rgba(25, 26, 35, 1) 0%,
      rgba(25, 26, 35, 0.58) 30%,
      rgba(43, 43, 43, 0.3) 50%,
      rgba(25, 26, 35, 0.58) 70%,
      rgba(25, 26, 35, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#191a23', endColorstr='#191a23',GradientType=1 );
  }
}

// .image {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   outline: none;
//   border: none;
// }

picture {
  // width: 100%;
  // height: 100%;
  // object-fit: cover;
  // outline: none;
  // border: none;
  img,
  source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
    border: none;
  }
}

@keyframes loading-animation {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
