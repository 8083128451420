.course-chat {
  display: flex;
  // flex-direction: column;
  // padding: 10px 20px;
  // height: 600px;
  flex: 1;
  .wrapper {
    // display: flex;
    // flex-direction: column;
    // height: 560px;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .chatlist {
      position: absolute;
      width: calc(100% - 40px);
      margin: 0 20px;
    }
    .chat-message {
      width: 100%;
      margin: 35px 0;
      gap: 14px;
      display: flex;
      position: relative;
      .avatar {
        width: 30px;
        height: 30px;
        img {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }
      .contents {
        position: relative;
        color: #000000;
        background-color: #a9a6a6;
        border-radius: 8px;
        padding: 12px;
        display: flex;
        flex: 1;
        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          position: absolute;
          top: -18px;
          width: calc(100% + 43px);
          left: -43px;
          .name {
            font-size: 0.7rem;
            font-weight: 300;
            color: #a7a5a5;
          }
          .time {
            color: #a7a5a5;
            font-size: 0.5625rem;
          }
        }
        .message {
          font-size: 0.8rem;
          line-height: 1.125rem;
          word-break: break-all;
        }
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 8px 10px;
          border-color: transparent transparent #a9a6a6 transparent;
          left: -10px;
          top: 10px;
        }
      }

      &.focus {
        flex-direction: row-reverse;
        .contents {
          background-color: white;
          .head {
            flex-direction: row-reverse;
            right: -44px;
            left: 0;
          }
          &::before {
            border-width: 0 0 8px 10px !important;
            border-color: transparent transparent transparent #ffffff !important;
            right: -10px !important;
            left: auto !important;
          }
        }
      }

      &.fromAdmin {
        .contents {
          border: 2px solid var(--accent-color-1);
          .head {
            .name,
            .time {
              color: white;
            }
          }
          &::before {
            border-color: transparent transparent #cc33ff transparent;
          }
        }
        &.focus {
          .contents {
            &::before {
              border-color: transparent transparent transparent #cc33ff !important;
            }
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    gap: 10px;
    margin-bottom: auto;
    margin-left: 20px;
    margin-right: 20px;
    input {
      &:focus {
        outline: none;
      }
      flex: 1 1 auto;
      line-height: 20px;
      padding: 8px 0 8px 8px;
      max-width: 100%;
      min-width: 0px;
      width: 100%;
      border-radius: 7px;
      border: none;
    }

    button.submitBtn {
      background: var(--gradient-bg-1);

      border: none;
      outline: none;
      border-radius: 6px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // svg {
      //   width: 15px;
      //   path {
      //   }
      //   fill: none;
      // }
    }
  }
}
