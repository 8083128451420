.root {
  display: flex;
  align-items: stretch;
  .form {
    display: flex;
    flex-direction: column;
    .picture {
      margin: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 20px;
      label {
        font-size: 0.875rem;
        color: var(--text-color-1);
      }

      .avatar {
        position: relative;
        img {
          width: 80px;
          height: 180px;
          border-radius: 50%;
        }
        .delete {
          cursor: pointer;
          border: 2px solid var(--main-bg);
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: var(--accent-color-2);
          top: 0px;
          right: -7px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 0.6em;
        }
      }
      .fileUpload {
        display: flex;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        width: 102px;
        margin-left: 30px;
        height: 42px;

        background: var(--gradient-bg-1);
        font-weight: bold;
        font-size: 1rem;
        letter-spacing: -0.16px;
        color: #fff;
        &:hover {
          background: var(--accent-color-1);
        }
        cursor: pointer;
      }
    }
    form {
      display: flex;
      flex: 1;
      flex-direction: column;

      margin: 10px;
      gap: 45px;
      @media only screen and (max-width: 960px) {
        gap: 20px;
      }
      .row {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 20px;
        @media only screen and (max-width: 960px) {
          flex-direction: column;
          gap: 20px;
        }
        .title {
          color: var(--text-color-1);
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.0625rem;
          margin-bottom: 5px;
        }
        .toggleNotifications {
          position: relative;
          display: flex;
          width: 50px;
          height: 24px;
          // margin-left: auto;

          input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--field-bg);
            -webkit-transition: 0.4s;
            transition: 0.4s;
            &.round {
              border-radius: 34px;
            }

            &.round:before {
              border-radius: 50%;
            }

            &.on {
              &:before {
                -webkit-transform: translateX(14px);
                -ms-transform: translateX(14px);
                transform: translateX(14px);
              }
            }
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 24px;
            width: 24px;
            left: 6px;
            // bottom: -1px;
            background: var(--gradient-bg-1);
            -webkit-transition: all 0.4s;
            transition: all 0.4s;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
          .label {
            position: absolute;
            right: -36px;
            top: 6px;
            font-size: 1rem;
            font-weight: 400;
            color: var(--link);
            opacity: 0.5;
            &.isOn {
              opacity: 1;
            }
          }
        }
        .fields {
          display: flex;
          gap: 30px;
          flex-wrap: wrap;
          .field {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 320px;
            height: 65px;
            @media only screen and (max-width: 1120px) {
              flex: 1;
            }
            @media only screen and (max-width: 960px) {
              width: 100%;
              height: 64px;
              flex: inherit;
            }
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    margin: 100px 10px 100px 10px;
    // margin-top: auto;
    gap: 20px;
    justify-content: space-between;
    & > *:last-child {
      margin-left: auto;
    }
    @media only screen and (max-width: 996px) {
      & > * {
        width: 50% !important;
      }
    }
    @media only screen and (max-width: 960px) {
      margin-top: 60px;
    }

    @media only screen and (max-width: 640px) {
      // margin-top: 50px;
      flex-direction: column;
      gap: 15px;
      & > * {
        width: 100% !important;
      }
    }
  }
}
