.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  button {
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: transparent;
    border: none;
  }
}
