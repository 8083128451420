.activity-timeline {
  margin: 20px;
  .activity-timeline-header {
    margin-bottom: 20px;
    .activity-timeline-header-wrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 11px;
      align-items: flex-end;
      .activity-timeline-title {
        font-size: 1.5rem;
        line-height: 1.5625rem;
        font-weight: 900;
        letter-spacing: -0.24px;
        color: #fff;
        margin-bottom: 0;
      }
      .activity-timeline-intervals {
        display: flex;
        gap: 52px;
        .activity-timeline-interval {
          display: flex;
          text-transform: capitalize;
          align-items: center;
          cursor: pointer;
          color: #fff;
          transition: color 0.25s;

          svg {
            padding: 0 6px;
            path {
              fill: white;
              transition: fill 0.25s;
            }
          }

          &.selected {
            color: var(--accent-color-1);
            svg path {
              fill: var(--accent-color-1);
            }
          }
          &:hover {
            color: var(--accent-color-1);

            svg path {
              fill: var(--accent-color-1);
            }
          }
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 3px;
          }
        }
      }
    }
    .activity-timeline-subtitle {
      letter-spacing: -0.2px;
      color: var(--accent-color-1);
      font-size: 1.25rem;
      font-weight: 900;
      text-transform: capitalize;
    }
  }
}
