.root {
  display: flex;
  align-items: stretch;
  height: 100%;
  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    width: calc(100vw - 240px);
    transition: margin-left 0.15s;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }

    .container {
      //   border: 1px solid red;
      flex: 1;
      padding: 20px;
      margin: 80px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
        margin-right: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #48515d;

        // border-right: 6px transparent solid;
        background-clip: padding-box;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .title {
        font-size: 1.6rem;
        color: #fff;
        font-weight: 600;
        letter-spacing: -0.18px;
        margin-bottom: 40px;
      }

      .statement {
        font-size: 0.85rem;
        color: #fff;
        font-weight: 400;
        letter-spacing: -0.18px;
      }

      .intro {
        margin: 40px auto;
        h5 {
          padding: 5px 0;
          margin: 0;
          color: var(--accent-color-1);
          font-size: 1rem;
          font-weight: 400;
        }
        p {
          padding: 0;
          margin: 0;
          color: var(--primary);
          font-size: 1rem;
          font-weight: 400;
        }
      }

      section {
        margin-top: 40px;
        margin-bottom: 40px;
        h1 {
          padding: 5px 0;
          margin: 0;
          color: #fff;
          font-size: 1.8rem;
          font-weight: 700;
        }

        .faq {
          margin: 30px auto;
          h2 {
            padding: 10px 0;
            margin: 0;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 700;
          }
          h3 {
            padding: 5px 0;
            margin: 0;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 400;
          }
          p {
            padding: 0;
            margin: 0;
            color: #c8c8c8;
            font-size: 1rem;
            font-weight: 400;
          }
          ul {
            list-style-type: none;
            margin-top: 20px;
            margin-bottom: 20px;
            li {
              padding: 2px 0 2px 30px;
              display: list-item;
              color: #c8c8c8;
              font-size: 1rem;
              font-weight: 400;
              &:before {
                content: "•";
                display: inline-block;
                width: 1em;
                margin-left: -1em;
              }
            }
          }
        }
      }
    }

    &.collapsed {
      margin-left: 50px;
      width: calc(100vw - 50px);
    }
  }
}
