.root {
  width: 25%;
  @media only screen and (max-width: 1600px) {
    width: 33.3%;
  }
  @media only screen and (max-width: 1260px) {
    width: 50%;
  }
  @media only screen and (max-width: 980px) {
    width: 100%;
  }
  .wrapper {
    height: 418px;
    background-color: var(--main-bg);
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px;
    border: 1px solid var(--secondary-bg);
    position: relative;
    .avatar {
      height: 195px;
      margin: 12px;
      position: relative;
      transition: all 0.2s;
      overflow: hidden;
      img {
        // object-fit: cover;
        // width: 100%;
        // height: 100%;
        border-radius: 7px;
      }
    }
    .subtitle {
      color: var(--text-color-1);
      font-size: 0.875rem;
      font-weight: 300;
      margin: 10px 20px 2px 20px;
    }
    .title {
      color: #fff;
      font-weight: 700;
      letter-spacing: -0.33px;
      margin: 0 20px 0 20px;
      font-size: 1.2rem;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      height: 43px;
      line-height: 1.3rem;
      overflow: hidden;
    }
    .description {
      color: var(--text-color-1);
      font-size: 1rem;
      font-weight: 300;
      height: 84px;
      letter-spacing: -0.32px;
      line-height: 1.3rem;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      margin: 10px 20px;
    }

    &:hover {
      .details {
        display: flex;
        animation: fadeIn ease 0.2s;
      }
    }
    .progress {
      margin: auto 12px 12px 12px;
    }

    .details {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--secondary-bg);

      /*CUSTOM SCROLL BAR*/
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        margin-right: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #48515d;

        border-right: 5px var(--secondary-bg) solid;
        background-clip: padding-box;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .header {
        color: var(--text-color-1);
        margin: 25px 12px 15px 12px;
        font-size: 0.875rem;
        font-weight: 300;
        overflow: hidden;
        padding: 0;
      }

      .list {
        display: flex;
        flex-direction: column;
        // background: red;
        flex: 1;
        width: 100%;
        margin-bottom: 12px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 4px;
          margin-right: 2px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          // box-shadow: inset 0 0 5px grey;
          border-radius: 8px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #48515d;

          // border-right: 6px transparent solid;
          background-clip: padding-box;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
