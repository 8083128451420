.root {
  display: flex;
  padding-left: 20px;
  flex-direction: row;
  flex: 0 1;
  margin-bottom: 20px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media only screen and (max-width: 780px) {
    display: none;
  }
  @media only screen and (max-height: 768px) {
    margin-bottom: 30px;
  }

  position: relative;
  cursor: pointer;

  .select {
    background-color: var(--field-bg);
    color: var(--link);
    border: 2px px solid var(--field-bg);
    border-radius: 8px;
    font-size: 1.125rem;
    height: 45px;
    outline: none;
    display: flex;
    z-index: 1;
    position: relative;
    flex: 1;

    &.show {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    label {
      cursor: text;
      position: absolute;
      left: 15px;
      top: 7px;
      background: transparent;
      color: var(--text-color-1);
      font-size: 0.75rem;
      transition: 0.3s;
      z-index: 2;
      border: none;
      outline: none;
    }
    .value {
      // background-color: red;
      align-items: center;
      margin: 20px 30px 4px 15px;
      border: none;
      outline: none;
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 20px;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-height: 1.2rem;
    }
    svg {
      position: absolute;
      right: 10px;
      top: 14px;
      transform: rotate(-90deg);
      opacity: 0.6;
      transition: all 0.25s;
      &.expanded {
        transform: rotate(90deg);
      }
    }
  }

  .options {
    display: flex;
    position: absolute;
    flex-direction: column;
    gap: 5px;
    height: 0;
    padding: 0;
    margin: 0;
    width: calc(100% - 20px);
    top: 45px;
    z-index: 5;
    background-color: var(--field-bg);
    // padding-top: 15px;
    // padding-bottom: 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    overflow-y: hidden;

    -webkit-transition: all 0.25s;
    transition: all 0.2s;

    &::-webkit-scrollbar {
      width: 4px;
      margin-right: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      border-radius: 8px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #48515d;

      // border-right: 6px transparent solid;
      background-clip: padding-box;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &.show {
      // display: flex;
      // flex-direction: column;
      // gap: 5px;

      padding-top: 15px;
      padding-bottom: 15px;
      height: auto;
      overflow-y: scroll;
      max-height: 300px;
    }
    .option {
      display: block;
      padding: 0;
      margin: 0;
      &:hover {
        label {
          color: var(--link);
        }
      }
      label {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        color: var(--link);
        font-size: 0.95rem;
        cursor: pointer;
        transition: all 0.15s;
        // &:hover {
        //   background: red;
        // }
      }
      input {
        // visibility: hidden;
        display: none;
        &:checked + label {
          color: var(--link);
        }
      }
    }
  }
}
