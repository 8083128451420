.root {
  display: flex;
  align-items: stretch;

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          // flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            line-height: 2.9375rem;
            color: var(--text-color-1);
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
        input {
          @media only screen and (max-width: 1600px) {
            min-width: 460px;
          }
          //   @media only screen and (max-width: 1164px) {
          //     min-width: 380px;
          //   }
          @media only screen and (max-width: 1080px) {
            width: 100%;
          }
        }
        // button {
        //   @media only screen and (max-width: 1600px) {
        //     margin-left: auto;
        //   }
        //   @media only screen and (max-width: 980px) {
        //     width: 100%;
        //   }
        // }
      }
      .contents {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        min-height: 400px;
        padding: 10px;
        flex: 1;
        .header2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          background: #13151a;
          border: 1px solid #303030;
          border-radius: 12px;
          gap: 30px;
          @media only screen and (max-width: 980px) {
            flex-direction: column;
          }
          .left {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .title {
              font-size: 1.5rem;
              font-weight: 400;
              // margin-bottom: 29px;
              line-height: 1.6rem;
              color: white;
            }
            .subtitle {
              font-size: 1.3rem;
              letter-spacing: -0.24px;
              color: var(--text-color-1);
              // margin-bottom: 10px;
            }
          }
          .right {
            display: flex;

            button {
              width: 120px;
              @media only screen and (max-width: 780px) {
                width: 200px;
              }
            }
          }
        }

        .list {
          display: flex;
          flex-direction: column;
          margin-top: 50px;
          min-height: 500px;
          position: relative;
          .title {
            font-size: 1.3rem;
            letter-spacing: -0.24px;
            color: var(--text-color-1);
            // margin: 0 5px;
          }
        }

        // .tabs {
        //   margin: 10px 10px 50px 10px;
        //   display: flex;
        //   flex-wrap: wrap;
        //   gap: 100px;
        //   border-bottom: 1px solid #303030;

        //   .tab {
        //     font-size: 1.2rem;
        //     font-weight: bold;
        //     padding: 15px 0;
        //     letter-spacing: -0.3px;

        //     color: #fefefe;
        //     cursor: pointer;
        //     position: relative;
        //     transition: color 0.2s;
        //     &.active {
        //       color: var(--accent-color-1);
        //       &::after {
        //         content: "";
        //         width: 100%;
        //         bottom: -2px;
        //         left: 0;
        //         height: 2px;
        //         background: var(--accent-color-1);
        //         position: absolute;
        //       }
        //     }
        //     &:hover {
        //       color: var(--accent-color-1);
        //       // border-bottom: 2px solid var(--accent-color-1);
        //     }
        //   }
        // }
        // .tabPanel {
        //   &.show {
        //     display: block;
        //   }
        //   &.hide {
        //     display: none;
        //   }
        // }
      }
    }
  }
}
