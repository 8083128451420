.second-btn {
  display: flex;
  border-radius: 15px;
  opacity: 0.9;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);

  justify-content: center;
  align-items: center;
  padding: 6px 12px;

  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);

  background-color: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(4px);
  -o-backdrop-filter: blur(4px);
  -moz-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);

  transition: background-color 0.25s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  cursor: pointer;

  .label {
    letter-spacing: -0.13px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    padding-top: 3px;
    margin: 0 4px;
  }
}
